/* eslint-disable no-undef */

<template>

  <div id="kmap">
    <div class="warningMessage" v-if="width>1023">Планировщик работает в тестовом режиме. Вопросы и предложения пишите на почту&nbsp;<a href="mailto:newmap@roadkursk.ru" class="link">newmap@roadkursk.ru</a>. Или подпишитесь на наш&nbsp;<a href="https://t.me/NewKurskOT" target="_blank" class="link">телеграм</a> </div>
    <div class="warningMessage" v-else>Планировщик работает в тестовом режиме.</div>
    <div :class="['ui',(uiShowed)?'':'hidden',mobile?'mobile':'']">
      <div class="place">
        <div class="maintitle" v-if="!newVersion">
          Схема маршрутов действующей сети Курского общественного трансп<span @click="debugmode=!debugmode" :class="[debugmode?'debcolor':'']">о</span>рта
        </div>
        <div class="maintitle" v-if="newVersion">
          Планировщик маршрутов новой сети Курского общественного трансп<span @click="debugmode=!debugmode" :class="[debugmode?'debcolor':'']">о</span>рта
        </div>
        <div class="closeUI" v-if="mobile && uiShowed" @click="()=>{uiShowed=false;map.invalidateSize()}"></div>

        <div class="options">
          <div :class="['optTitle',optPlaceOpen?'opened':'']" @click="()=>{optPlaceOpen=!optPlaceOpen}">Настройки</div>
          <div class="optMenu" v-if="optPlaceOpen">
            <div :class="['oneOpt',moreWaysShowed?'checked':'']" @click="selectMoreWays()"><span></span>Выбор нескольких маршрутов</div>
            <div :class="['oneOpt',stopsShowed?'checked':'']" v-if="stopsLoaded" @click="showStops()"><span></span>Показать остановки</div>
          </div>

        </div>

        <div class="fromto" v-if="(options!=null && newVersion)">
          <multiselect v-model="fromPoint" :options="options"  :show-labels="false" track-by="id" label="text" placeholder="откуда">
            <template slot="singleLabel" slot-scope="{ option }">{{option.text}}</template>
            <template slot="option" slot-scope="props">
              <div class="option__desc"><span class="option__title">{{ props.option.text }}</span><span class="option__small">{{ props.option.desc }}</span></div>
            </template>
          </multiselect>
          <StopWay v-if="fromPoint!=null" v-bind:stopid="fromPoint" v-on:shWay="showWay($event)"/>
          <multiselect v-model="toPoint" :options="options" :show-labels="false" track-by="id" label="text" placeholder="куда">
            <template slot="singleLabel" slot-scope="{ option}">{{option.text}}</template>
            <template slot="option" slot-scope="props">
              <div class="option__desc"><span class="option__title">{{ props.option.text }}</span><span class="option__small">{{ props.option.desc }}</span></div>
            </template>
          </multiselect>
          <div v-if="debugmode">
            {{fromPoint}}
            {{toPoint}}
          </div>

        </div>
        <div class="fromtoControl" v-if="(options!=null && newVersion)">
          <div :class="['goPath',(fromPoint!=null && toPoint!=null)? 'active':'']" @click="findWay()" :style="{cursor:(fromPoint!=null && toPoint!=null && !searchNow)?'pointer':'unset'}">
            <span :style="{opacity:searchNow?0:1}">Построить маршрут</span>
            <span id="app-preloader" v-if="searchNow"><span></span><span></span><span></span></span>
          </div>
          <div class="clearPath" @click="clearPath()" v-if="fromPoint!=null || toPoint!=null">Очистить</div>
        </div>
        <div v-if="0">
          <div @click="()=>{this.fromPoint={ id: 99, text: '1 Весенний проезд'};this.toPoint={id: 237, text: 'АЗС (Резинщик)' }}">Забить данные</div>
          {{fromPoint}}{{toPoint}}
        </div>

        <div class="workPlace">

          <!-- Маршруты -->
          <div v-if="ways!=null && currentStop==null && findResult==null && way==null">
            <div class="absPos">
            <div class="devider"></div>
            <vue-custom-scrollbar class="scroll-area"  :settings="settings" >
            <div class="ways" >
              <div class="cat" v-for="(way,index) in ways" :key="index">
                <div class="titleClearPlace">
                  <div class="title">
                    {{way.name}}
                  </div>
                  <div class="waysClear" v-if="index==0 && moreWaysPull.length>0">
                    <span @click="clearWaysPull()">Очистить</span>
                  </div>
                </div>


                <div class="places">
                  <div  v-if="index!=2">
                    <button :class="['names','type'+index,(moreWaysPull.indexOf(name.id) >= 0)?'active':'']" v-for="name in way.data" :key="name.id" @click="showWay(name.id)" :style="[(moreWaysPull.indexOf(name.id) >= 0)?{color:'#fff'}:{},(moreWaysPull.indexOf(name.id) >= 0)?{background: 'rgb('+name.color[0]+','+name.color[1]+','+name.color[2]+')'}:{}]">
                      {{name.name}}
                    </button>
                  </div>
                  <div v-else>
                    <div class="parentCat" v-for="cat in way.bycat" :key="cat.name">
                      <div class="catstyle">{{cat.name}}</div>
                      <button :class="['names','type'+index,(moreWaysPull.indexOf(name.id) >= 0)?'active':'']" v-for="name in cat.data" :key="name.id" @click="showWay(name.id)" :style="[(moreWaysPull.indexOf(name.id) >= 0)?{color:'#fff'}:{},(moreWaysPull.indexOf(name.id) >= 0)?{background: 'rgb('+name.color[0]+','+name.color[1]+','+name.color[2]+')'}:{}]">
                        {{name.name}}
                      </button>
                    </div>

                  </div>

                </div>

              </div>
              <div class="contactUs">
                Что-то не работает? Напишите нам на почту <a href="mailto:newmap@roadkursk.ru" class="link">newmap@roadkursk.ru</a>.<br>
                Или подпишитесь на наш телеграм-канал
                <a href="https://t.me/NewKurskOT" target="_blank" class="button">Телеграм-канал</a>
              </div>
            </div>
            </vue-custom-scrollbar>
            </div>
          </div>
          <!-- //Маршруты -->
          <!-- Остановка детально -->
          <Stop v-if="currentStop!=null" v-bind:stopid="currentStop" v-on:bback="currentStop = $event" v-on:showWay="showWay($event)" v-on:tPoint="toPoint = $event" v-on:fPoint="fromPoint = $event"></Stop>

          <!-- //Остановка детально -->
          <!-- Результат поиска -->
          <Paths v-if="findResult!=null" v-bind:data="findResult" v-bind:clicked="clickedBusStop" v-on:drawPath="drawPaths($event)"/>
          <!-- //Результат поиска -->
          <!-- Информация о маршруте -->
          <Way v-if="way!=null " v-bind:way="way" v-bind:clicked="clickedBusStop" v-on:drawPath="drawPaths($event,(Math.random() * 1000000))" v-on:CloseDetail="() => {this.clearMap();way=null;moreWaysPull=[]}"/>
          <!-- //Информация о маршруте -->
        </div>

      </div>

    </div>
    <div class="blackBGMobile" v-if="mobile && uiShowed"></div>
    <div id="map" :class="[mobile?'mobile':'']"></div>

    <div :class="['controlUI',mobile?'mobile':'']">
      <div class="mobileShow" v-if="mobile && !uiShowed && way==null" @click="uiShowed=true">Показать маршруты</div>
      <div class="mobileShow" v-if="mobile && !uiShowed && way!=null" @click="uiShowed=true">Показать маршрут</div>
      <div class="spacer"></div>
      <a :class="['stops','active',mobile?'fixedDown':'']" href="https://new.kurskmap.ru" v-if="!newVersion">Новая маршрутная сеть</a>
      <a :class="['stops','active',mobile?'fixedDown':'','long']" href="/img/newWeb.png" v-if="newVersion" target="_blank">Печатная схема маршрутной сети</a>
      <div :class="['stops']" @click="showTransport()" v-if="debugmode">Транспорт</div>

    </div>
    <div :class="['controlUIRight',mobile?'mobile':'']">
      <div class="zoomIn" @click="map.setZoom(map.getZoom() + 1)"></div>
      <div class="zoomOut" @click="map.setZoom(map.getZoom() - 1)"></div>
    </div>


  </div>
</template>
<script>
//import { Loader } from "@googlemaps/js-api-loader"
import Multiselect from 'vue-multiselect'
import Stop from "@/components/Stop";
import Paths from "@/components/Paths";
import Way from "@/components/Way";
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import StopWay from "@/components/StopWay";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

export default {
  name: 'App',
  data() {
    return {
      newVersion: true,
      debugmode: false,
      google:null,
      map: null,
      fromPoint: null,
      toPoint: null,
      options: null,
      ways: null,
      way: null,
      line: null,
      wayElement: null,
      stops: [],
      stopsShowed: false,
      mapStops: null,
      currentStop: null,
      stopsLoaded: false,
      findResult: null,
      drawedpath:[],
      popups:[],
      searchNow:false,
      Popup: "",
      clickedBusStop:null,
      width: 0,
      mobile: false,
      uiShowed: true,
      pcat: null,
      moreWaysShowed: false,
      moreWaysPull: [],
      settings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: false
      },
      optPlaceOpen: false,
    }
  },
  components: { Multiselect, Stop,Paths, Way,vueCustomScrollbar, StopWay },
  created() {
    window.addEventListener("resize", this.windowResized);
  },
  destroyed() {
    window.removeEventListener("resize", this.windowResized);
  },
  mounted() {
    this.mapInit()
    this.fromtoInit()
    this.waysInit()
    this.loadStops()
    this.windowResized()
  },
  methods: {
    console: (data) => {
      console.warn(data)
    },
    sortedbyCat(json) {
      json.sort(function(a, b){
        return a.catid - b.catid;
      });
      return json
    },
    pcatChange(catname) {
      if (this.pcat!=catname) {
        this.pcat=catname;
        return true;
      } else {
        return false;
      }
    },
    windowResized() {
      this.width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
      this.mobile=this.width<800?true:false;
      this.uiShowed=true;
    },
    mapInit() {
      let bounds = L.latLngBounds(L.latLng(52.437157, 34.551404),L.latLng(51.049578, 37.546473))
      this.map = L.map("map",{
        zoomControl: false,
        minZoom: 13
      }).setView([51.730846, 36.193015], 13).setMaxBounds(bounds);
      //L.tileLayer("https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}@2x.png", {
      L.tileLayer("https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png", {
      }).addTo(this.map);
      this.map.on('drag', function() {
        this.map.panInsideBounds(bounds, { animate: false });
      });

    },
    fromtoInit() {
      let _this=this
      this.axios.get(_this.$root.apiUrl+'busstops', )
          .then(function (response) {
              _this.options=response.data
          })
          .catch(function (error) {
            console.log(error)
          });
    },
    waysInit() {
      let _this=this
      this.axios.get(_this.$root.apiUrl+'ways', )
          .then(function (response) {
            _this.ways=response.data
          })
          .catch(function (error) {
            console.log(error)
          });
    },
    showWay(id) {
      let _this=this
      if (this.line!=null) {
        this.line.setMap(null)
        this.line=null
        alert(1)
      }
      this.axios.get(_this.$root.apiUrl+'way/'+id, )
          .then(function (response) {
            if(!_this.moreWaysShowed) {
              _this.way=response.data
              _this.currentStop=null
            }

            _this.uiShowed=false

            _this.drawPaths({path:[response.data.newdata.routeF.stops],route:[response.data.newdata.routeF.route],color:[response.data.background]},id)


            if(_this.stopsShowed) {
              _this.showStops()
            }

          })
          .catch(function (error) {
            console.log(error)
          });
    },
    zoomToObject(lines){
      var bounds =[];
      lines.forEach(function (obj) {
        var points = obj[0].getBounds();
        bounds.push(points)
      })
      this.map.fitBounds(bounds);
    },
    loadStops() {
      if (this.stops.length==0) {
        let _this = this
        this.axios.get(_this.$root.apiUrl+'geojson', )
            .then(function (response) {
              _this.stops = response.data
              _this.stopsLoaded = true
            })
            .catch(function (error) {
              console.log(error)
            });
      }
    },

    showTransport() {
      let _this = this
      this.axios.get(_this.$root.apiUrl+'gps', )
          .then(function (response) {
            _this.stops = response.data
            _this.stopsLoaded = true
          })
          .catch(function (error) {
            console.log(error)
          });
    },
    showStops() {
      this.optPlaceOpen = false
      let _this=this
      if (!this.stopsShowed) {

        this.mapStops = L.geoJSON(this.stops,{
        pointToLayer: function(geoJsonPoint, latlng) {
          return L.marker(latlng,{
            icon: L.divIcon({
              html: '<div class="marker-icon" style="background: #00f;"></div>'
            })
          })
        },
        onEachFeature: function(feature, layer) {
          let html = "<b class='stop__Title'>"+feature.properties.nameext+"</b><br>";
          html+='<div class="stop__Ways">';
          feature.properties.ways.forEach(function (item) {
            html+='<div class="stop__Way">'+item.name+'</div>'
          })
          html+='</div>';
          layer.bindPopup(html)
          layer.on('mouseover', function () {
            this.openPopup();
          });
          layer.on('mouseout', function () {
            this.closePopup();
          });
          layer.on('click', function() {
            _this.currentStop=feature.properties.nameextid
            _this.uiShowed = true
            _this.way=null
          })
        }
        })
        this.mapStops.addTo(_this.map)

        this.stopsShowed=!this.stopsShowed
      } else {
        this.map.removeLayer(this.mapStops)
        this.stopsShowed=!this.stopsShowed
    }
    },
    selectMoreWays() {
      this.moreWaysShowed=!this.moreWaysShowed
      if (!this.moreWaysShowed) {
        this.moreWaysPull=[]
        this.clearMap()
      }
      this.optPlaceOpen = false

    },
    clearWaysPull() {
      this.moreWaysPull=[]
      this.clearMap()
    },
    clearPath() {
      this.fromPoint = null
      this.toPoint = null
      this.clearMap()
      this.findResult = null
    },
    findWay() {
      if(this.fromPoint!=null && this.toPoint!=null && !this.searchNow) {
        this.searchNow=true
        this.way=null
        let _this = this
        _this.findResult = null
        this.axios.post(_this.$root.apiUrl+'find', {from: this.fromPoint.id,to:this.toPoint.id})
            .then(function (response) {
              _this.findResult = null
              _this.findResult =response.data
              console.warn(response.data)
              _this.searchNow = false
              if(_this.stopsShowed) {
                _this.showStops()
              }
            })
            .catch(function (error) {
              console.log(error)
              _this.searchNow = false
            });
      }

    },
    drawPaths(data,multiid) {

      let _this = this
      if (!this.moreWaysShowed) {
        this.clearMap()
        this.drawedpath = new Array();
        this.popups = new Array();
      }
      if (this.moreWaysPull.indexOf(multiid) >= 0) {
        this.clearMap(multiid)
      } else {
        data.route.forEach(function (item,index) {//цикл для каждой линии
          let pathLine = new Array();
          let popupsLine = new Array();
          item.forEach(function (cord) {//соберем координаты пути
            pathLine.push(L.latLng([parseFloat(cord[0]), parseFloat(cord[1])]));
          })
          const line = L.polyline(pathLine, {
            color:data.color[index],
            weight: 4,
            opacity: 1
          })
          line.addTo(_this.map)
          _this.map.fitBounds(line.getBounds());
          _this.drawedpath.push([line,multiid])
          _this.moreWaysPull.push(multiid)
          //остановки
          data.path[index].forEach(function (stop,pos) {

            if ((pos==0 && index==0)|| (pos==data.path[index].length-1 && index==data.route.length-1)) {
              //начка и конечка
              let popup = L.tooltip(L.latLng([stop.latitude, stop.longitude]),{
                content: '<div class="popup-bubble" style="background: '+data.color[index]+';white-space: nowrap">'+stop.name+'</div>',
                sticky: true,
                permanent: true,
                opacity: 1
              })
              popup.openOn(_this.map)

              popupsLine.push(popup)
            } // else {
              //others
            let marker = L.marker(L.latLng([stop.latitude, stop.longitude]), {
              icon: L.divIcon({
                html: '<div class="marker-icon" style="background: '+data.color[index]+'"></div>'
              })
            })
            let html = "<b class='stop__Title'>"+stop.name+"</b><br>";
            html+='<div class="stop__Ways">';
            stop.ways.forEach(function (item) {
              html+='<div class="stop__Way">'+item.name+'</div>'
            })
            html+='</div>';
            marker.bindPopup(html)
            marker.on('mouseover', function () {
              this.openPopup();
            });
            marker.on('mouseout', function () {
              this.closePopup();
            });
            marker.on('click', function() {
              _this.clickedBusStop=stop.name;
            })
            marker.addTo(_this.map)
            popupsLine.push(marker)
            //}
          })
          _this.popups.push([popupsLine,multiid])

        })
      }


      this.zoomToObject(this.drawedpath)

    },
    clearMap(way = null) {
      let _this = this
      if (way!=null) {
        if (this.drawedpath.length>0) {
          this.drawedpath.forEach(function (line) {
            if (line[1] == way) {
              _this.map.removeLayer(line[0])
            }
          })
        }
        if (this.popups.length>0) {
          this.popups.forEach(function (popup) {
            if (way==popup[1]) {
              popup[0].forEach(function (pop) {
                _this.map.removeLayer(pop)
              })
            }
          })
        }
        let delWay = _this.moreWaysPull.indexOf(way)
        _this.moreWaysPull.splice(delWay,1)

      } else {
        if (this.drawedpath.length>0) {
          _this.drawedpath.forEach(function (line) {
            _this.map.removeLayer(line[0])
          })
        }
        if (this.popups.length>0) {

          this.popups.forEach(function (popup) {
            popup[0].forEach(function (pop) {
              _this.map.removeLayer(pop)
            })

          })
        }
      }
      if (this.line!=null) {
        this.map.removeLayer(this.line)
        this.line=null
      }
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>

#kmap {
  position: fixed;
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  background-color: #fff;
}
.blackBGMobile {
  position: absolute;
  background: black;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  opacity: .4;
}
.closeUI {
  background: #FFFFFF;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 33px;
  height: 33px;
  position: absolute;
  right: -40px;
  top: 35px;
}
.closeUI:before {
  width: 9px;
  height: 14px;
  content: "";
  position: absolute;
  left: calc(50% - 6px);
  top: calc(50% - 7px);
  background: url("~@/assets/closeUI.svg") 0 0 no-repeat;
}
.options {
  padding: 0 41px 0 59px;
  margin-bottom: 20px;
  position: relative;

}
.options .optTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background: #F2F4F5;
  border-radius: 3px;
  padding: 7px 10px;
  width: calc(100% - 22px);
  position: relative;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  border: 1px solid #D8D8D8;
}

.options .optTitle.opened {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-color: transparent;
}

.options .optTitle:after {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: "";
  position: absolute;
  right: 15px;
  top: 10px;
  transition: all ease-out .2s;
}
.options .optTitle.opened:after {
  transform: rotate(180deg);
}
.options .optMenu {
  background: #F2F4F5;
  border-radius: 3px;
  padding: 6px 10px 15px 24px;
  position: absolute;
  left: 59px;
  right: 41px;
  z-index: 2;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #D8D8D8;
  border-top-color: transparent;
}
.options .oneOpt {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #A4ABAF;
  margin-bottom: 14px;
  position: relative;
  transition: all ease-out .2s;
  cursor: pointer;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}
.options .oneOpt.checked {
  color: #26A242;
}
.options .oneOpt span{
  position: absolute;
  width: 10px;
  height: 9px;
  background: url("~@/assets/optUnChecked.png") 0 center no-repeat;
  position: absolute;
  left: -14px;
  top: 4px;
}
.options .oneOpt.checked span{
  position: absolute;
  width: 10px;
  height: 9px;
  background: url("~@/assets/optChecked.png") 0 center no-repeat;
  position: absolute;
  left: -14px;
  top: 4px;
}
.options .oneOpt:last-of-type {
  margin-bottom: 0;
}
@media all and (max-width: 374px){
  .closeUI {
    right: 20px;
    background: #F2F4F5;
    box-shadow: unset;
    top: 35px;
  }
}
.contactUs {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #757575;
  padding-bottom: 30px;
}
.contactUs .link {
  text-decoration: none;
  color: #416F8F;
  transition: all ease-out .3s;
}
.contactUs .link:hover {
  color: #1D4664;
  text-decoration: none;
}
.contactUs .button {
  position: relative;
  display:block;
  text-align: center;
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding: 11px 10px 12px;
  color: #FFFFFF;
  background: #007BC2;
  border-radius: 3px;
  margin-top: 9px;
  transition: all ease-out .3s;
}
.contactUs .button:hover {
  background:  #005586;
}
.contactUs .button:before {
  position: absolute;
  top: 12px;
  left:12px;
  width: 20px;
  height: 15px;
  content: "";
  background: url("~@/assets/tgIcon.svg") 0 0 no-repeat;
}
#kmap #map {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 320px;
  right: 0;
  z-index: 1;
  transition: all ease-out .3s;
}
#kmap #map.mobile {
  left: 0;
}
.warningMessage {
  position: fixed;
  top: 0;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFC700;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  z-index: 2;
}
.warningMessage .link {
  color: #000;
  text-decoration: none;
  border-bottom: 1px solid rgba(0,0,0,.2);
  transition: all ease-out .3s;
}
.warningMessage .link:hover {
  border-color: transparent;
}
.warningMessage .button {
  display: inline-block;
  background: #ECB800;
  border-radius: 3px;
  padding: 3px 10px 5px;
  text-decoration: none;
  color: #000;
  margin-left: 15px;
  transition: all ease-out .3s;
}
.warningMessage .button:hover {
  background: #E8A702;
  border-radius: 3px;
}
.ui {
  position: fixed;
  left: 0px;
  top: 40px;
  display: flex;
  z-index: 3;
  height: 100vh;
  transition: all ease-out .3s;
}
.ui.mobile.hidden {
  left: -320px;
}
.ui .place {
  width: 320px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.ui .maintitle {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  padding-left: 23px;
  position: relative;
  margin: 35px 50px 30px 35px;
}
.ui .maintitle:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 17px;
  height: 16px;
  background: url('~@/assets/logo.svg') 0 0 no-repeat;
}
.ways {
  margin: 15px 40px 30px 59px;
}
.mobile .ways {
  padding-bottom: 100px;
}
.ways .titleClearPlace {
  display: flex;
}
.ways .title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  flex-grow: 1;
}
.ways .titleClearPlace .waysClear{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #0078BF;
  cursor: pointer;
}
.ways .names {
  display: inline-block;
  background: #F2F4F5;
  border-radius: 3px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  text-align: center;
  padding: 8px 0;
  width: 39px;
  position: relative;
  margin-bottom: 5px;
  margin-right: 5px;
  border: 0;
  transition: all ease-out .3s;
}
.ways .names:hover {
  background: #DAE0E4;
  cursor: pointer;
}
.ways .names.type0:after {
  content: "";
  background: inherit;
  height: 2px;
  left: 7px;
  right: 7px;
  position: absolute;
  bottom: -4px;
  transition: all ease-out .3s;
}
.ways .names.type1:after {
  content: "";
  background: inherit;
  height: 2px;
  left: 7px;
  right: 7px;
  position: absolute;
  top: -4px;
  transition: all ease-out .3s;
}
.ways .cat .places {
  padding-top: 15px;
  padding-bottom: 20px;
}
.ways .cat:last-of-type {
  padding-bottom: 30px;
}
.controlUI {
  position: absolute;
  right: 15px;
  top: 35px;
  z-index: 1;
  display: flex;
}

.controlUI .stops {
  padding: 7px 12px 9px;
  background: #FFFFFF;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  transition: all ease-out .3s;
  cursor: pointer;
  text-decoration: none;
}
.controlUI .stops.active {
  background: #26A242;
  color: #fff;
  text-decoration: none;
}

.controlUI.mobile .mobileShow {
  padding: 7px 12px 9px;
  background: #26A242;
  border-radius: 3px;
  color: #fff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-right: 15px;
}
.controlUI.mobile {
  display: flex;
  left: 15px;
  right: 15px;
}
.controlUI .spacer {
  flex-grow: 1;
}
.workPlace {
  flex-grow: 1;
  position: relative;
}
.fromto {
  padding: 0 41px 0 59px;
}
.fromtoControl {
  margin-top: 3px;
  display: flex;
  white-space: nowrap;
  padding: 0 41px 0 59px;
  margin-bottom: 20px;
}
.fromtoControl .goPath {
  background: #8EC59A;
  border-radius: 3px;
  padding: 7px 12px 9px;
  color: #B2D9BB;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all ease-out .3s;
  position: relative;
}
.fromtoControl .goPath.active {
  background: #26A242;
  color: #fff;
}
.fromtoControl .goPath.active:hover {
  background: #1F8A37;
  color: #fff;
}
.fromtoControl .clearPath {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #9CACBA;
  padding: 7px 12px 9px;
  background: #F2F4F5;
  border-radius: 3px;
  transition: all ease-out .3s;
}
.fromtoControl .clearPath:hover{
  background: #E4EBEF;
  cursor: pointer;
}
#app-preloader {
  height: 100%;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  background: #26A242;
  justify-content: center;
}
#app-preloader span {
  background: #fff;
  display: inline-block;
  height: 10px;
  margin: 0 5px;
  position: relative;
  border-radius: 50%;
  vertical-align: top;
  width: 10px;
}
#app-preloader span:nth-child(1) {
  animation: pulse 1s infinite 0.2s;
}
#app-preloader span:nth-child(2) {
  animation: pulse 1s infinite 0.4s;
}
#app-preloader span:nth-child(3) {
  animation: pulse 1s infinite 0.6s;
}
#app-preloader span:nth-child(4) {
  animation: pulse 1s infinite 0.8s;
}
#app-preloader span:nth-child(5) {
  animation: pulse 1s infinite 1s;
}
.debcolor {
  opacity: .7;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.absPos {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
.controlUIRight {
  top: 88px;
  right: 30px;
  z-index: 1;
  width: 33px;
  position: absolute;
}
.controlUIRight.mobile {
  right: 15px;
}
.controlUIRight .zoomIn,.controlUIRight .zoomOut {
  background: #FFFFFF;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 33px;
  height: 33px;
  margin-bottom: 10px;
  position: relative;
  transition: all ease-out .3s;
  cursor: pointer;
}
.controlUIRight .zoomIn:hover,.controlUIRight .zoomOut:hover {
  background: #F2F4F5;
}
.controlUIRight .zoomIn:before,.controlUIRight .zoomOut:before {
  content: "";
  width: 13px;
  height: 1px;
  background-color: #9CACBA;
  position: absolute;
  left: 10px;
  top: 50%;
}
.controlUIRight .zoomIn:after {
  content: "";
  width: 1px;
  height: 13px;
  background-color: #9CACBA;
  position: absolute;
  left: 16px;
  top: 11px;
}


.catstyle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #9CACBA;
  margin-bottom: 5px;
}
.parentCat {
  margin-top: 10px;
}
.parentCat:first-of-type {
  margin-top: -10px;
}
.controlUI .stops.fixedDown {
  position: fixed;
  left: calc(50% - 95px);
  bottom: 30px;
}
.controlUI .stops.fixedDown.long {
  left: calc(50% - 122px);
}
@media all and (min-width: 320px) and (max-width: 375px){
  .controlUI.mobile .mobileShow {

    margin-right: 10px;
  }
  .controlUI.mobile .stops {

  }
  .controlUI .stops {
    margin-right: 10px;
  }
}
</style>
<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
body {
  margin: 0;
  padding: 0;
}
* {
  font-family: 'Inter', sans-serif;
}
.stop__Title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.stop__Ways {
  margin-top: 11px;
}

.stop__Way {
  box-sizing: border-box;
  display: inline-block;
  padding: 1px 6px 2px;
  transition: all ease-out .3s;
  margin-right: 5px;
  min-width: 30px;
  background: #F2F4F5;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 5px;
  font-family: 'Inter', monospace;

}
.stop__Way:last-of-type {
  margin-right: 0;
}
.gm-style .gm-style-iw-t::after {
  content: unset;
}
.gm-style .gm-style-iw-d {
  margin-right: -12px;
  margin-bottom: -5px;
  width: 160px;
}
.gm-style .gm-style-iw-c button {
  display: none!important;
}
.gm-style .gm-style-iw-c {
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: translate3d(5px,calc(-100% + 6px),0);
  background-color: white;
  border-radius: 3px;
  padding: 6px 6px 5px;
  box-shadow: 0px 0px 1px rgb(0 0 0 / 10%);
}
.fromto .multiselect{
  margin-bottom: 5px;
  position: relative;
}
.fromto .multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 7px 30px 11px 10px;
  border-radius: 3px;
  border: 1px solid transparent;
  background: #F2F4F5;
  font-size: 14px;
}
.fromto .multiselect__input, .fromto .multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: #F2F4F5;
  padding: 0;
  width: 100%;
  transition: border .1s ease;
  box-sizing: border-box;
  margin-bottom: 0px;
  vertical-align: top;
}
.fromto .multiselect, .fromto .multiselect__input {
  font-family: inherit;
  font-size: 14px;
  touch-action: manipulation;

}
.fromto .multiselect__single {
  font-family: inherit;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fromto .multiselect__placeholder {
  margin-bottom: 0;
  padding-top: 0;
}
.fromto .multiselect:first-of-type:before, .fromto .multiselect:last-of-type:before {
  content: "А";
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #2D2F32;
  left: -24px;
  top: 10px;
}
.fromto .multiselect:last-of-type:before {
  content: "Б";
}
.workPlace .devider {
  margin: 0 5px 0 20px;
  border-bottom: 1px solid #F2F4F5;
}
.ps {
  height: 100px;
}
.leaflet-control-attribution {
  display: none!important;
}
.leaflet-tooltip {
  background: transparent;
  color: unset;
  box-shadow: none;
  padding: 0px;
  border: 0;
  color: transparent;
}
.leaflet-tooltip:before {
  content: none;
}
.popup-bubble {
  /* Position the bubble centred-above its parent. */
  position: absolute;
  top: -10px;
  left: calc(50%);
  transform: translate(-50%, -100%);
  /* Style the bubble. */
  background-color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #FFFFFF;
  padding: 3px 6px 5px;
  overflow-y: auto;
  max-height: 60px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 3px;
}
.leaflet-div-icon {
  border: 1px solid #fff;
  border-radius: 50%;
  overflow: hidden;
}
.leaflet-div-icon > div {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.popup-bubble-anchor {
  position: absolute;
  width: 100%;
  bottom: 2px;
  left: 0;
}
.popup-container {
  cursor: auto;
  height: 0;
  position: absolute;
  /* The max width of the info window. */
  width: 200px;
}
.multiselect__option--highlight {
  background: #26A242;
  outline: none;
  color: #fff;
}
.multiselect__option {
  transition: all ease-out .1s;
}
.option__title {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.option__small {
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0,0,0,.7);
}
.multiselect__option--highlight .option__title {
  color: #fff;
}
.multiselect__option--highlight .option__small {
  color: rgba(255,255,255,.7);
}
.multiselect__element,.multiselect__option {
  overflow: hidden;
  width: 100%;
}
.multiselect__element::-webkit-scrollbar {
  width: 0;
}
.controlUI .stops {
  margin-right: 15px;
}

</style>
