<template>
  <div class="way">
    <div class="devider"></div>
    <vue-custom-scrollbar class="scroll-area"  :settings="settings" >
    <div class="info">
      <div class="toBack" @click="$emit('CloseDetail',null)"></div>
      <div class="title">
        {{way.newdata.name}}
      </div>
      <div class="infomass" v-html="infoRoute">
      </div>

      <div class="cRoute">
        <div :class="['changeRoute',routeF?'active':'']"  @click="changeDirect('f')"><span>{{ way.newdata.routeF.from }}</span> — <span>{{ way.newdata.routeF.to }}</span></div>
        <div :class="['changeRoute',routeB?'active':'']"  @click="changeDirect('b')" v-if="way.newdata.routeB.length!=0"><span>{{ way.newdata.routeB.from }}</span> — <span>{{ way.newdata.routeB.to }}</span></div>
      </div>
    </div>


    <div v-if="routeF" class="routePlace">
      <div class="waysplace">

        <div class="pathPlace">
          <div class="leftLine" :style="{backgroundColor: way.background}"></div>
          <div :class="['pathStop']" >
            <div
                :class="['stop',(index==0)?'start':'',(index+1==way.newdata.routeF.stops.length)?'end':'',(way.newdata.routeF.stops.length>hideMin && index>hideStart && index<(way.newdata.routeF.stops.length-hideEnd) && hideMore)?'hidden':'',(clicked==wayM.name)?'clicked':'']"
                v-for="(wayM,index) in way.newdata.routeF.stops"
                :key="wayM.id+getRandomInt(10000)" >
              <div class="leftPoint" :style="{backgroundColor: (index==0 || index+1==way.newdata.routeF.stops.length)?way.background:((index==hideStart && hideMore)?way.background:'#ffffff'),borderColor:(index==hideStart && hideMore)?'#FAFAFA':way.background}"></div>

              <span v-if="index!=hideStart || !hideMore">{{wayM.name}}</span>

              <span v-if="index==hideStart && hideMore" class="showMore" @click="hideMore=false">еще {{way.newdata.routeF.stops.length-hideStart-hideEnd}} {{chisl((way.newdata.routeF.stops.length-hideStart-hideEnd),['остановка','остановки','остановок'])}}</span>
              <!--// расписание//-->
              <div v-if="index==0 && timesFrom!=null" >
                <div class="timesPlace" :class="[(show==-1)?'compact':'']">
                  <div :class="{'is-active': show == ind}" v-for="(days, ind) in timesFromDays" :key="ind+getRandomInt(1000000)" @click.prevent="()=> {if(show!=ind) {show = ind;} else {show = -1}}">
                    {{days[0]}}
                  </div>
                </div>


                <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                <transition-group name="fade-up" target="div" appear >

                  <div :class="['timesSpace']" v-for="(rasp, indr) in timesFromDays" :key="indr+getRandomInt(1000000)" v-if="show == indr">
                    <div class="timeLegend">
                      <div>ч</div>
                      <div>мин</div>
                    </div>
                    <div v-for="(hours,indexh) in rasp[1]" :key="indexh+getRandomInt(1000000)" class="hours">
                      <span>{{hours[0]}}</span>
                      <div v-for="(minutes,indexm) in hours[1]" :key="indexm+getRandomInt(1000000)" class="minutes">
                        {{minutes}}
                      </div>
                    </div>
                  </div>
                </transition-group>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div v-if="routeB" class="routePlace">
      <div class="waysplace">

        <div class="pathPlace">
          <div class="leftLine" :style="{backgroundColor: way.background}"></div>
          <div :class="['pathStop']" >
            <div
                :class="['stop',(index==0)?'start':'',(index+1==way.newdata.routeB.stops.length)?'end':'',(way.newdata.routeB.stops.length>hideMin && index>hideStart && index<(way.newdata.routeB.stops.length-hideEnd) && hideMore)?'hidden':'',(clicked==wayM.name)?'clicked':'']"
                v-for="(wayM,index) in way.newdata.routeB.stops"
                :key="wayM.id+getRandomInt(10000)">
              <div class="leftPoint" :style="{backgroundColor: (index==0 || index+1==way.newdata.routeB.stops.length)?way.background:((index==hideStart && hideMore)?way.background:'#ffffff'),borderColor:(index==hideStart && hideMore)?'#FAFAFA':way.background}"></div>

              <span v-if="index!=hideStart || !hideMore" >{{wayM.name}}</span>

              <span v-if="index==hideStart && hideMore" class="showMore" @click="hideMore=false">еще {{way.newdata.routeB.stops.length-hideStart-hideEnd}} {{chisl((way.newdata.routeB.stops.length-hideStart-hideEnd),['остановка','остановки','остановок'])}}</span>
              <!--// //-->

              <div v-if="index==0 && timesTo!=null" >
                <div class="timesPlace" :class="[(show==-1)?'compact':'']">
                  <div :class="{'is-active': show == ind}" v-for="(days, ind) in timesToDays" :key="ind+getRandomInt(1000000)" @click.prevent="()=> {if(show!=ind) {show = ind;} else {show = -1}}">
                    {{days[0]}}
                  </div>
                </div>
                <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                <transition-group name="fade-up" target="div" appear >
                  <div :class="['timesSpace']" v-for="(rasp, indr) in timesToDays" :key="indr+getRandomInt(1000000)" v-if="show == indr">
                    <div class="timeLegend">
                      <div>ч</div>
                      <div>мин</div>
                    </div>
                    <div v-for="(hours,indexh) in rasp[1]" :key="indexh+getRandomInt(1000000)" class="hours">
                      <span>{{hours[0]}}</span>
                      <div v-for="(minutes,indexm) in hours[1]" :key="indexm+getRandomInt(1000000)" class="minutes">
                        {{minutes}}
                      </div>
                    </div>
                  </div>
                </transition-group>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    </vue-custom-scrollbar>
  </div>
</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
export default {
  name: 'Paths',
  props: ['way','clicked'],
  components: { vueCustomScrollbar },
  mounted() {
    this.initData()
    this.parseTimes()
  },
  data() {
    return {

      routeF:false,
      routeB:false,
      hideMore:true,
      hideMin: 7,
      hideStart: 3,
      hideEnd: 3,
      infoRoute: null,
      timesFrom: null,
      timesFromDays: null,
      timesToDays: null,
      timesTo: null,
      showtimes: false,
      show: -1,
      settings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: false
      }
    }
  },
  watch: {

    clicked: {
      handler(newValue, oldValue) {
        if(newValue !== oldValue) {
          let _this=this
          this.hideMore = false
          setTimeout(function () {
            var el = _this.$el.getElementsByClassName("clicked")[0];
            el.scrollIntoView({block: 'center'});
          },50)


        }
      },
      deep: true //если нужно отслеживать изменение свойств
    }
  },
  methods: {
    initData() {
      this.routeF=true
    },
    chisl(number, titles) {
      var cases = [2, 0, 1, 1, 1, 2];
      return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
    },
    changeDirect (dir) {
      switch (dir) {
        case 'f':
          if (this.routeB) {
            this.$emit('drawPath',{path:[this.way.newdata.routeF.stops],route:[this.way.newdata.routeF.route],color:[this.way.background]})
            this.routeB=false
            this.routeF=true
            this.hideMore=true
          }
          break
        case 'b':
          if (this.routeF) {
            this.$emit('drawPath',{path:[this.way.newdata.routeB.stops],route:[this.way.newdata.routeB.route],color:[this.way.background]})
            this.routeF=false
            this.routeB=true
            this.hideMore=true
          }
          break
      }
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    parseTimes() {
      var tmsTo = null
      var tmsFrom = null


      this.infoRoute = this.way.newdata.info;
      var fT = this.infoRoute.split('@');
      if (fT.length==3) {
        this.infoRoute = fT[0] + fT[2];
        tmsFrom = fT[1];
      }
      var tT = this.infoRoute.split('&');
      if (tT.length==3) {
        this.infoRoute = tT[0] + tT[2];
        tmsTo = tT[1];
      }
      //распарсим расписание
      //распарсим с началки
      if (tmsFrom!=null) {
        var resF = this.parseByDays(tmsFrom);
        this.timesFrom = resF[0];
        this.timesFromDays = resF[1];
      }
      // распарсим с конечки
      if (tmsTo!=null) {

        var resT = this.parseByDays(tmsTo);
        this.timesTo = resT[0];
        this.timesToDays = resT[1];

      }
    },
    parseByDays(del) {
      var hoursT = null;
      var resultArr = [];
      var tempMin = [];
      var bydays = [];
      var day='';
      var tmsFrom = del.replace(/ /g,'')
      var fromArray = tmsFrom.split(',');
      hoursT = null;
      resultArr = [];
      tempMin = [];
      //добавим дни недели
      bydays = [];

      for (var key in fromArray) {
        //содержимое элементов
        if (fromArray[key].indexOf('$')> -1) {

          if (day!='') {

            resultArr.push([hoursT,tempMin]);
            bydays.push([day,resultArr]);

            resultArr=[]
            hoursT = null
            tempMin = []
          }
          day = fromArray[key].replace('$', '');
          continue;
        }

        var time = fromArray[key].split(':');
        if (time[0] != hoursT) {
          if (hoursT != null) {
            resultArr.push([hoursT,tempMin]);
            tempMin = [];
            hoursT = time[0];
          } else {
            hoursT = time[0];
          }
        }
        tempMin.push(time[1]);
      }
      if (fromArray.length > 1) {
        resultArr.push([hoursT,tempMin]);
      }
      bydays.push([day,resultArr])
      return [resultArr,bydays];
    }
  }
}
</script>

<style scoped>
.way {
  position: absolute;
  top: 0;
  bottom: 0;

  left: 0;
  right: 0;
}
.infomass {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #A4ABAF;
  margin-bottom: 20px;
  margin-top: 5px;
}
.info {
  padding: 0 41px 0 59px;
  margin-top: 15px;
}
.info .title {
  ont-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  color: #000000;
}
.cRoute {
  margin: 8px 0;
}
.cRoute .changeRoute.active {
  color: #26A242;
  background: #EBFAEE;
  cursor: unset;
}
.cRoute .changeRoute {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #A4ABAF;
  padding: 4px 6px 5px;
  background: #FAFAFA;
  border-radius: 3px;
  transition: all ease-out .3s;
  margin-bottom: 10px;
  cursor: pointer;
}
.cRoute .changeRoute span {
  white-space: nowrap;
}
.toBack {
  position: absolute;
  width: 39px;
  height: 39px;
  left: 20px;
  top: 9px;
  cursor: pointer;
}
.toBack:before {
  content: "";
  position: absolute;
  width: 11px;
  height: 2px;
  left: 14px;
  top: 16px;
  background: #A4ABAF;
  transform: matrix(0.71, -0.67, 0.75, 0.71, 0, 0);
  transition: all ease-out .3s;
}
.toBack:after {
  content: "";
  position: absolute;
  width: 11px;
  height: 2px;
  top: 22px;
  left: 14px;
  background: #A4ABAF;
  transform: matrix(0.71, 0.67, -0.75, 0.71, 0, 0);
  transition: all ease-out .3s;
}
.toBack:hover:after,.toBack:hover:before {
  background: #000;
}
.pathPlace {

  position: relative;
}
.showMore {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  padding: 4px 8px 5px;
  color: #9CACBA;
  margin-left: -8px;
  background: #F2F4F5;
  border-radius: 3px;
}
.pathPlace .leftLine {
  position: absolute;
  width: 0px;
  border-left: 1px solid transparent;
  top: 30px;
  left: -15px;
  bottom: 25px;
}
.pathStop .stop {
  padding: 17px 0 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  position: relative;
  border-bottom: 1px solid #EEEEEE;
}
.pathStop .stop.hidden {
  display: none;
}
.pathStop .stop .leftPoint{
  position: absolute;
  width: 5px;
  height: 5px;
  border: 2px solid transparent;
  border-radius: 50%;
  left: -19px;
  top: calc(50% - 4px);
}
.pathStop .stop.start,.pathStop .stop.end {
  font-weight: 600;
}
.pathStop .stop.end {
  border-color: transparent;
}
.waysplace {
  display: flex;
  align-items: center;
  padding: 0 41px 40px 59px;
  background-color: #FAFAFA;
  align-items: baseline;
  height: calc(100% - 40px);
}
.routePlace {
  flex-grow: 1;
}
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.stop:before {
  content: "";
  background: transparent;
  position: absolute;
  left: -5px;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 5px;
  transition: all ease-out .3s;
}
.stop.clicked:before {
  background: #F2F4F5;
  z-index: 1;
}
.stop.clicked > span {
  position: relative;
  z-index: 1;
  color: #26A242;
}
.timesSpace {
  display: flex;
  flex-direction: column;

}

.timesSpace .hours {
  display: flex;
  flex-flow: row wrap;
}
.timesSpace .hours > span{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  min-width: 20px;
}
.timesSpace .hours .minutes:nth-of-type(7n){
  margin-left: 30px;
}
.timesSpace .hours > * {
  margin-right: 10px;
}
.timesSpace .hours > *:last-child {
  margin-right: 0px;
}
.timesSpace .hours .minutes {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #738898;
  min-width: 20px;
  text-align: left;
}
.timesPlace {
  margin: 5px 0 10px;
  display: flex;
}
.timesPlace.compact {
  margin: 5px 0 -19px;
}
.timesPlace > div {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #377ABD;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  transition: all ease-out .3s;
  margin-right: 10px;
}
.timesPlace > div:hover, .timesPlace > div.is-active {
  border-color: #377ABD;
}
.timesPlace > div:last-child {
  margin-right: 0;
}
.timeLegend {
  display: flex;
}
.timeLegend >div{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  min-width: 20px;
  color: #738898;
}
.timeLegend >div:first-of-type{
  margin-right: 10px;
}
</style>
